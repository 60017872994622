<template>
<!-- eslint-disable -->
	<div>
		<h2 class="text-center" style="font-size: 28px!important; margin: 70px 0;">takeno残高</h2>

		<h1 id="balance" class="text-center mt-5" style="font-size: 40px;">{{ animatedBalance }}<span style="font-size: 20px;"> takeno</span></h1>

		<v-row style="margin-top: 90px;">
			<v-spacer></v-spacer>
			<v-chip v-for="item in iconList" :key="item.id" class="mx-2" outlined large @click="router(item.path)"><v-icon>{{ item.icon }}</v-icon></v-chip>
			<v-spacer></v-spacer>
		</v-row>
		
		<v-dialog
			v-model="popFlg"
			persistent
			max-width="90%"
			>
			<v-card v-for="message in messageList" :key="message.id" class="gradation mb-3">
				<div class="text-center pt-6 pb-4 pl-2" style="white-space: pre-wrap;">
					<h4 v-if="!openFlg">{{ message.send_from }} さんからtakenoが届きました！</h4>
					<p v-if="openFlg && message.message">{{ message.message }}</p>
					<p v-if="openFlg && !message.message">メッセージなし</p>
				</div>
				<v-card-actions>
					<v-btn v-if="!openFlg" block tile color="red" dark depressed small @click="openFlg = true" class="mb-2">
						開く
					</v-btn>
					<v-btn v-if="openFlg" block tile color="red" dark depressed small @click="togglePop()" class="mb-2">
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


	</div>
</template>
<script>
/* eslint-disable */
import * as api from '@/api.js'
  export default {
	name: 'Takeno',
    data () {
      return {
		// ダイアログ
		popFlg: false,
		openFlg: false,
		messageList: [],
		message: '',
		// 残高
		balance: 0,
		tweenedNumber: 0,
		iconList:[
			{ icon: 'mdi-cash-multiple', path: 'Purchase'},
			{ icon: 'mdi-gift', path: 'Gift'},
			{ icon: 'mdi-treasure-chest', path: 'Gacha'},
		]
      }
    },

	methods: {
		load(){
			api.takeno.get().then((res) => {
                switch(res.data.status) {
                    case 200:
						this.balance = res.data.content.takeno
						this.messageList = res.data.content.message
						if(this.messageList.length != 0) this.pop(this.messageList)
                        break
                    case 500:
						this.$router.push({ name: 'Home' })
                        break
                }
			}).catch()
		},
		router(path) {
			this.$router.push({ name: path })
		},
		pop(message) {
			this.popFlg = true // childの値を書き換え
			this.message = message // childの値を書き換え
		},
        togglePop() {
            this.popFlg = !this.popFlg
        },
	},
	created() {
		this.load()
	},
	computed: {
		animatedBalance: function() {
			return this.tweenedNumber.toFixed(0);
		}
	},
	watch: {
		balance: function(newValue) {
      		gsap.to(this, { duration: 1, tweenedNumber: newValue });
    	}
	}
  }
</script>

<style></style>
